.navbar {
  height: 76px;
  background-color: #ffffff;

  .nav-text {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #192031;
  }
  .search-field {
    width: 220px;
    height: 40px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .divider {
    width: 0px;
    height: 24px;
    border: 1px solid #b3b3b3;
  }
  .messages {
    width: 20px;
    height: 20.06px;
  }
  .notifications {
    width: 20px;
    height: 20px;
  }
  .profile-name {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #192031;
  }
}
