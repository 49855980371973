.info-password {
  .custom-textfield {
    border-radius: 10px !important;
    width: 100%;
    height: 60px;
    margin-bottom: 24px !important;
  }
  .typography {
    margin-bottom: 8px !important;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }
  .typography-text {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    color: #2d74ff;
    margin-bottom: 24px !important;
  }
  .cancel-button {
    text-transform: none;
    width: 97px;
    height: 45px;
    margin-right: 20px !important;
    background: #ffffff;
    color: #1480f3;
    border: 1px solid #1480f3 !important;
    border-radius: 6px !important;
    padding: 11px 18px;
    text-align: center;
    font-size: 18px !important;
    font-weight: 500;
    font-family: "Plus Jakarta Sans" !important;
  }
}
.email-info {
  .email-heading {
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #000000 !important;
  }
  .email-text {
    font-family: "Plus Jakarta Sans";
    font-style: normal ;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: rgba(0, 0, 0, 0.7);
  }
}
