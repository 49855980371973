.instructions-list {
  margin-top: 1rem;
}

.instruction-item {
  padding-bottom: 0.5rem;
}

.last-date-to-apply {
  padding: 1rem;
  cursor: pointer;
  border-color: #fafafa;
  border-radius: 5px;
}
.typography {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.custom-textfield .MuiOutlinedInput-root {
  border-radius: 10px;
}
.custom-textfield .MuiOutlinedInput-root ::placeholder {
  font-size: 16px;
  color: #000000;
  font-weight: 300;
  font-family: "Plus Jakarta Sans";
}
.textArea {
  font-family: "Inter";
}
.textarea-field {
  width: 100%;
  resize: none;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid rgba(196, 202, 205, 0.4);
 font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #676767;
}
.form-container{
  height: 80vh;
  overflow: scroll;
  overflow-x: hidden;
}

@media (max-width: 1440px) {
  .form-container {
    width: 1575px !important; /* Width for large screens and larger */
  }
}

