.dashboard-cards {
  display: flex;
  flex-direction: column;
  // width: 261px;
  height: 216px;
  padding: 20px;
  border-radius: 10px;
  background: #ffffff;

  .vertical-line{
    width: 5px;
    height: 58px; 
    background: #1480F3; 
    border-radius: 10px
  }

  .card-img{
    width: 50px;
    height:50px
  }

  .dashboard-cards-title {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: rgba(25, 32, 49, 0.4);
  }
  .dashboard-cards-tags {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    color: rgba(25, 32, 49, 0.7);
  }
  .dashboard-cards-result {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #192031;
  }
}
