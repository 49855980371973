.sidebar {
  width: 248px;
  border-right: 0.5px solid rgba(230, 227, 227);
  height: 100vh;
  min-height: 740px;
  position: relative;
  background: #1480f3;
  .top {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .horizental-line {
    width: 140px;
    height: 0px;
    border: 1px solid #ffffff;
  }
  .divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
    width: 100%;
    .minimize {
      padding: 0px 15px;
    }
  }

  .center {
    .title1 {
      padding-left: 25px;
      padding-bottom: 10px;
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: #ffffff;
    }
    .icon {
      width: 24px;
      height: 24px;
    }
    .sidbar-box {
      display:flex;
      padding: 15px 25px;
      text-decoration: none;
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0rem;
      color: #ffffff;
      opacity: 0.75;
      .icon-text {
        padding-left: 15px;
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(12px);
        color: #ffffff;
        opacity: 1;
      }
      .logout-text {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        padding-left: 15px;
       
      }
    }
  }
}
