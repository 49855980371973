.data-table {
  max-height: 700px;
  max-width: calc(100vw - 330px);
  overflow: auto;
  // padding: 20px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 20px 30px;

  .css-80pr5n-MuiPaper-root {
    height: 100% !important;
    border-radius: 10px;
  }

  .data-table-title {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .cell-with-img {
    display: flex;
    align-items: center;

    .cell-img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cell-with-status {
    padding: 5px;
    border-radius: 5px;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    &.active {
      // background-color: rgba(0, 128, 0, 0.05);
      // color: green;
      // width: 100px;
      display: inline-block;
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3px 10px;
      gap: 10px;
      background: rgba(19, 168, 125, 0.2);
      border-radius: 100px;
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #13a87d;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 70, 70, 0.2);
      color: #d74646;
      width: 84px !important;
      padding: 3px 20px;
      text-align: center;
      border-radius: 100px;
    }
    &.in-progress {
      display: inline-block;
      padding: 3px 15px;
      background: rgba(57, 169, 152, 0.2);
      color: #39a998;
      border-radius: 100px;
    }
    &.todo {
      display: inline-block;
      padding: 3px 15px;
      background: rgba(3, 120, 71, 0.2);
      color: #037847;
      border-radius: 100px;
    }
    &.blocked {
      display: inline-block;
      padding: 3px 15px;
      background: rgba(246, 146, 119, 0.2);
      color: #f69277;
      border-radius: 100px;
    }
    &.done {
      display: inline-block;
      padding: 3px 15px;
      background: rgba(20, 128, 243, 0.2);
      color: #1480f3;
      border-radius: 100px;
    }
    &.published{
      display: inline-block;
      padding: 3px 15px;
      background: rgba(19, 168, 125, 0.2);
      color: #13A87D;
      border-radius: 100px;
    }
    &.draft{
      display: inline-block;
      padding: 3px 15px;
      background: rgba(0, 0, 0, 0.16);
      color: #000000 ;
      border-radius: 100px;

    }
  }

  .applied-job-status{
    display: inline-block;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    gap: 10px;
    border-radius: 100px;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    &.active {
      background: rgba(19, 168, 125, 0.2);
      color: #13a87d;
    }

    &.accepted{
      background: rgba(20, 128, 243, 0.2);
      color: #1480f3;
    }
    &.rejected{
      background: rgba(246, 146, 119, 0.20);
      color: #F69277;
    }
    &.pre-screening{
      background: rgba(245, 173, 13, 0.20);
      color: #F5AD0D;
    }
    &.reviewed{
      background: rgba(3, 120, 71, 0.20);
      color: #037847;
    }

  }

  .cell-with-assignee {
    display: inline-block;
    padding: 3px 15px;
    font-size: 14px;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.06);
    color: #000000;
    border-radius: 100px;
  }

  .cell-action {
    display: flex;
    align-items: center;
    gap: 15px;

    .view-button {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }

    .delete-button {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .data-table {
    max-height: 500px;
    overflow: auto;
  }
}

.table-icon {
  cursor: pointer;
}

[data-column-id="sr_no"].rdt_TableCol,
[data-column-id="position"].rdt_TableCol,
[data-column-id="location"].rdt_TableCol,
[data-column-id="status"].rdt_TableCol,
[data-column-id="action"].rdt_TableCol,
[data-column-id="file"].rdt_TableCol,
[data-column-id="position"].rdt_TableCol,
[data-column-id="name"].rdt_TableCol,
[data-column-id="email"].rdt_TableCol,
[data-column-id="review"].rdt_TableCol {
  font-weight: 700;
  font-size: 16px;
}
