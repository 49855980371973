.DataGrid{
    max-height: 700px;
    max-width: calc(100vw - 330px);
    overflow: auto;
    // padding: 20px;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin: 20px 30px;
    
}

  .time-input {
    width: 50px; 
    border: 1px solid grey; 
    outline: none;
    font-size: 16px; 
    padding: 16px;
  }
  
.custom-time-picker {
  width: 230px; 
  height: 32px; 
}

.time-picker-container {
  margin-left: 10px; 
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
   width: 600px;
  height: 400px;
  background-color: #fff;
  text-align: left;
 align-items: center;
  border-radius: 20px;
}
.modal-btn{
  width: 160px;
  height: 45px;
  background-color: rgba(255, 255, 255, 1);
color: rgba(215, 70, 70, 1);
border-radius: 10px; 
border:1px solid rgba(215, 70, 70, 1) ; 
// margin-top: 36vh;
margin-right: 10px;
cursor: pointer;
}
.close-button {
  background-color: transparent;
  border: 2px solid black;
  color: rgb(20, 18, 18);
  cursor: pointer;
  font-size: 16px;
  border-radius: 7px;
 padding-top: 3px;
  
}

.close-button:hover {
  text-decoration: underline;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
 background-color: rgba(0, 0, 0, 0.04);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 88px;
 
}
.modal-btn-2{
  width: 160px;
  height: 45px;
  background-color: rgba(20, 128, 243, 1);
  color: rgb(244, 245, 248);
  border-radius: 10px; 
  border: none;
  margin-right: 20px;
cursor: pointer;
}
.filter-btn{
  display: flex;
  margin-top: 3vh;
  margin-left: 15vw;
  cursor: pointer;
  // justify-content:space-between;
}
.FormGroup{
  display: flex;
}
.select-type{
  margin-left: 20px;
  margin-top: 5vh
}
.select-name{
  margin-left: 20px;
  margin-top: 2vh;
  padding-right: 10px;
}
.select-date{
  margin-left: 20px;
  margin-top: 2vh;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
}
.h4{
  font-size: 15px;
font-weight: 500;
}
.Filterscreen{
  width: 81px;
  height: 35px;
  border-radius: 6px ;
  border:1px solid rgba(45, 116, 255, 1) ;
   background:rgb(255, 255, 255);
  color:  rgba(45, 116, 255, 1);
font-size: 15px;
font-weight: 500;
margin-left: 10px;
cursor: pointer;
}
.filter-icon{
  width: 18px;
  height: 18px;
  // margin-right: 5px;
margin-right: 10px;
}
.export{
  width: 81px;
  height: 35px;
  border-radius: 6px ;
  border:1px solid rgba(45, 116, 255, 1) ;
   background:rgb(255, 255, 255);
  color:  rgba(45, 116, 255, 1);
font-size: 15px;
font-weight: 500;
margin-left: 10px;
cursor: pointer;
}
.group-icon{
  width: 18px;
  height: 18px;
  // margin-right: 5px;
margin-right: 10px;
}
.custom-field{
  margin-right: 10px;
}
.date-component{
  width:136.23px;
height:40px;
border: 0.5 solid rgb(17, 12, 12);
border-radius: 5px;
}

.leave-form{
  width: 70vw;
  height: 80vh;
  background:#fff;
  align-items: center;
  margin-top: 5vh;
  margin-left: 9vw;
  border-radius: 10px;
}
.leave-card{
  width: 364px;
  height: 268px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 30px;
  margin-left: 30px;

}
.emp-name{
  padding-top: 10px;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}
.emp-data{
  width: 334px;
  height: 93px;
  background-color: rgba(245, 247, 250, 1);
  border-radius: 10px;
  margin-left: 13px;
  margin-top: 20px;
}
.emp-data{
  display: flex;
}
.card-area{
  max-height: 700px;
  max-width: calc(100vw - 330px);
  overflow: auto;
  display: flex; 
  flex-wrap: wrap;
}
.filterss{
  display: flex;
  justify-content:space-between;
}
.btn-group{
  border-radius: 10px;
}

.selected-button {
  color: black; 
}
.date{
  width: 140px;
  height: 32px;
  border-radius: 10px;
  border: 0.5 solid rgba(45, 116, 255, 1) ;
}
.menu-btn{
margin-right: 10px;
border-radius: 10px;
}
.popup-1 {
  width: 680px;
 height: 780px;
 background-color: #fff;
 text-align: left;
align-items: center;
 border-radius: 20px;
}
.close-button-1{
  background-color: transparent;
  border: 2px solid black;
  color: rgb(20, 18, 18);
  cursor: pointer;
  font-size: 16px;
  border-radius: 7px;
 padding-top: 3px;
  
}

.close-button-1:hover {
  text-decoration: underline;
}
.status{
  width: 334px;
  height: 93px;
  background-color: rgba(245, 247, 250, 1);
  border-radius: 10px;
  margin-left: 13px;
  margin-top: 20px;
}