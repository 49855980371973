.revenue-container {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;

  .revenue-card {
    height: 319px;
    padding: 20px;
    border-radius: 10px;
    background: #ffffff;
  }

  .revenue-card-tags {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: rgba(25, 32, 49, 0.4);
    padding-bottom: 10px;
    padding-top: 20px;
  }

  .tags-circle {
    width: 13px;
    height: 13px;
    border-radius: 100px;
    margin-right: 10px;
  }
  .tags-values {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #192031;
  }
}
