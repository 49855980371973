.data-table {
  


  .data-table-title {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .cell-with-img {
    display: flex;
    align-items: center;

    .cell-img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cell-with-status {
    padding: 5px;
    border-radius: 5px;

    &.active {
      // background-color: rgba(0, 128, 0, 0.05);
      // color: green;
      // width: 100px;
      display: inline-block;
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3px 10px;
      gap: 10px;
      background: rgba(19, 168, 125, 0.2);
      border-radius: 100px;
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #13a87d;
    }
    &.pending {
      background-color: rgba(245, 173, 13, 0.2);
      color:#F5AD0D
    }
    &.passive {
      background-color: rgba(215, 70, 70, 0.2);
      color: #D74646;
      width: 84px !important;
      font-size: 14px;
      padding: 3px 20px;
      text-align: center;
      border-radius: 100px;
    }
  }

  .cell-action {
    display: flex;
    align-items: center;
    gap: 15px;

    .view-button {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }

    .delete-button {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
}

.table-icon {
  cursor: pointer;
}

[data-column-id="sr_no"].rdt_TableCol,
[data-column-id="position"].rdt_TableCol,
[data-column-id="location"].rdt_TableCol,
[data-column-id="status"].rdt_TableCol,
[data-column-id="action"].rdt_TableCol,
[data-column-id="file"].rdt_TableCol,
[data-column-id="position"].rdt_TableCol,
[data-column-id="name"].rdt_TableCol,
[data-column-id="email"].rdt_TableCol,
[data-column-id="review"].rdt_TableCol {
  font-weight: 700;
  font-size: 16px;
}
